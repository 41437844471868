<template>

        <!-- attachment preview modal starts here -->
        <transition name="modal">
            <div class="modalBg modal-active" v-if="attachmentModal.isOpen">
                <div class="modalLayers" @click.prevent="attachmentModal.isOpen = false"></div>
                <div class="modalBody">
                    <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
                        <div class="productZoomImg">
                            <img 
                                :src="attachmentModal.url" 
                                alt=""
                                @error="handleImageError"
                                v-if="attachmentModal.type == 'image'"
                            >
                            <video :src="attachmentModal.url" controls v-if="attachmentModal.type == 'video'"></video>
                        </div>
                    </div>
                    <div class="modalCloseIcon">
                        <button @click.prevent="attachmentModal.isOpen = false">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </transition>
        <!-- attachment preview modal ends here -->
         
</template>

<script>
export default {
    name: 'attachmentPreview',
    data(){
        return {
            attachmentModal:{
                type: '',
                url: '',
                isOpen: false,
            },
        }
    },
    props:{
    },
    components:{
    },
    computed:{
    },
    watch:{
    },
    methods:{

        openModal({ type, url }){

            this.attachmentModal = {
                type: type,
                url: url,
                isOpen : true
            }

        },

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },
        
    },
}
</script>

<style scoped>
/* Transition Effects */
.modal-enter-active, 
.modal-leave-active {
  transition: all 0.3s ease-in-out;
}

/* Initial state when entering */
.modal-enter{
  opacity: 0;
  visibility: hidden;
}

/* Final state when leaving */
.modal-leave-to {
  opacity: 0;
  visibility: hidden;
}
</style>